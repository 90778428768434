import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './translations/en.json'; 
import frTranslation from './translations/fr.json';
import nlTranslation from './translations/nl.json'; 

const storedLanguage = localStorage.getItem('selectedLanguage');

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      'fr-FR': {
        translation: frTranslation,
      },
      nl: {
        translation: nlTranslation,
      },
    },
    lng: storedLanguage || 'fr-FR', 
    fallbackLng: 'fr-FR',
    interpolation: {
      escapeValue: false, 
    },
  });

export default i18n;
